import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AuthService } from "src/app/auth/auth.service";
@Component({
  selector: "app-menu-modal",
  templateUrl: "./menu-modal.component.html",
  styleUrls: ["./menu-modal.component.scss"],
})
export class MenuModalComponent implements OnInit {
  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<MenuModalComponent>,
    private authService: AuthService
  ) {}

  ngOnInit() {}

  onOpenDashboard() {
    this.router.navigate(["/home"]);
    this.dialogRef.close(true);
  }

  onOpenReport() {
    this.router.navigate(["/report"]);
    this.dialogRef.close(true);
  }

  onOpenRapot() {
    this.router.navigate(["/profile/details"]);
    this.dialogRef.close(true);
  }

  onLogout() {
    this.authService.removAuth();
    this.dialogRef.close(true);
    this.router.navigate(["/signin"]);
  }

  onOpenKomisi() {
    this.router.navigate(["/komisi"]);
    this.dialogRef.close(true);
  }

  onOpenLeads() {
    this.router.navigate(["/leads"]);
    this.dialogRef.close(true);
  }

  onOpenProduksi() {
    this.router.navigate(["/produksi"]);
    this.dialogRef.close(true);
  }
}
