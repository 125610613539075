import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { BodyComponent } from "./core/body/body.component";

const routes: Routes = [
  {
    path: "",
    component: BodyComponent,
    children: [
      {
        path: "",
        redirectTo: "/home",
        pathMatch: "full",
      },
      {
        path: "home",
        loadChildren: () =>
          import("./core/core.module").then((m) => m.CoreModule),
      },
      {
        path: "report",
        loadChildren: () =>
          import("./components/report/report.module").then(
            (m) => m.ReportModule
          ),
      },
      {
        path: "komisi",
        loadChildren: () =>
          import("./components/komisi/komisi.module").then(
            (m) => m.KomisiModule
          ),
      },
      {
        path: "leads",
        loadChildren: () =>
          import("./components/leads/leads.module").then(
            (m) => m.LeadsModule
          ),
      },
      {
        path: "leads-detail",
        loadChildren: () =>
          import("./components/leads-detail/leads-detail.module").then(
            (m) => m.LeadsDetailModule
          ),
      },
      {
        path: "details",
        loadChildren: () =>
          import("./components/details/details.module").then(
            (m) => m.DetailsModule
          ),
      },
      {
        path: "produksi",
        loadChildren: () =>
          import("./components/produksi/produksi.module").then(
            (m) => m.ProduksiModule
          ),
      },
    ],
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./components/profile-page/profile-page.module").then(
        (m) => m.ProfilePageModule
      ),
  },
  {
    path: "signin",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
