import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  AfterViewInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as AppActions from "src/app/actions/app.action";
import * as fromAll from "../../reducers/allreducer.reducer";
import { CoresService } from "../core.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, AfterViewInit {
  units = [];
  totalPointToday = null;

  constructor(
    private store: Store<fromAll.AllState>,
    private coreService: CoresService
  ) {
    this.store.dispatch(new AppActions.SetTitle("HOME"));
    this.store.dispatch(new AppActions.SetBackUrl("/profile/home"));
  }

  ngOnInit() {}

  getData() {}

  ngAfterViewInit() {
    this.coreService.getContentHome().subscribe((data) => {
      this.units = data.units.filter((unit) => unit.subtitle !== "Kpop");
      this.totalPointToday = data.total;
    });
  }

  openAccordion() {}

  toggleAccordian(event, index) {
    const element = event.target;
    const parent = element.parentElement;
    const grandParent = parent.parentElement;
    const grandGrandParent = grandParent.parentElement;

    element.classList.toggle("active");
    if (this.units[index].isActive) {
      this.units[index].isActive = false;
    } else {
      this.units[index].isActive = true;
    }
    const panel = grandGrandParent.nextElementSibling;

    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
      panel.style.maxHeight = null;
      grandGrandParent.style.background = "#ffffff";
      grandGrandParent.style.color = "#F07805";
      element.style.backgroundColor = "#F07805";
      element.style.color = "#fff";
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
      grandGrandParent.style.background =
        "linear-gradient(90deg, rgba(97,116,161,1) 0%, rgba(43,58,92,1) 100%)";
      element.style.backgroundColor = "#ffffff";
      element.style.color = "#667AA7";
      grandGrandParent.style.color = "#ffffff";
    }
  }
}
