import { NgModule } from "@angular/core";
import { RupiahPipe } from "./rupiah.pipe";
import { RoundPipe } from "./round.pipe";
import { RatioPipe } from "./ratio.pipe";
import { DayPipe } from "./day.pipe";
import { DayDatePipe } from "./daydate.pipe";
import { MonthYearPipe } from "./monthyear.pipe";
import { DateIndoPipe } from "./date-indo.pipe";
import { RoundTwoDigitPipe } from "./roundTwo.pipe";
import { HumanizeTextPipe } from "./humanize-text.pipe";

@NgModule({
  declarations: [
    RupiahPipe,
    RoundPipe,
    RatioPipe,
    DayPipe,
    DayDatePipe,
    MonthYearPipe,
    DateIndoPipe,
    RoundTwoDigitPipe,
    HumanizeTextPipe,
  ],
  imports: [],
  exports: [
    RupiahPipe,
    RoundPipe,
    RatioPipe,
    DayPipe,
    DayDatePipe,
    MonthYearPipe,
    DateIndoPipe,
    RoundTwoDigitPipe,
    HumanizeTextPipe,
  ],
})
export class PipesModule {}
